import dayjs from 'dayjs';
import { IOption } from 'app/shared/model/option.model';
import { IPoll } from 'app/shared/model/poll.model';
import { PollType } from 'app/shared/model/enumerations/poll-type.model';

export interface IQuestion {
  id?: number;
  description?: string | null;
  serialNo?: number | null;
  pollType?: keyof typeof PollType | null;
  question?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  options?: IOption[] | null;
  poll?: IPoll | null;
}

export const defaultValue: Readonly<IQuestion> = {};
