import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserDevice from './user-device';
import UserDeviceDetail from './user-device-detail';
import UserDeviceUpdate from './user-device-update';
import UserDeviceDeleteDialog from './user-device-delete-dialog';

const UserDeviceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserDevice />} />
    <Route path="new" element={<UserDeviceUpdate />} />
    <Route path=":id">
      <Route index element={<UserDeviceDetail />} />
      <Route path="edit" element={<UserDeviceUpdate />} />
      <Route path="delete" element={<UserDeviceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserDeviceRoutes;
