import dayjs from 'dayjs';
import { IPoliticianIssues } from 'app/shared/model/politician-issues.model';
import { ICandidate } from 'app/shared/model/candidate.model';

export interface IPolitician {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  imagePath?: string | null;
  party?: string | null;
  dob?: string | null;
  age?: number | null;
  birthPlace?: string | null;
  about?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  level?: string | null;
  office?: string | null;
  ocdDivisionId?: string | null;
  twitterHandle?: string | null;
  facebookHandle?: string | null;
  isLeader?: boolean | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  leaderIssues?: IPoliticianIssues[] | null;
  candidates?: ICandidate[] | null;
}

export const defaultValue: Readonly<IPolitician> = {
  isLeader: false,
};
