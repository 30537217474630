import dayjs from 'dayjs';
import { IDivision } from 'app/shared/model/division.model';
import { IUser } from 'app/shared/model/user.model';

export interface IUserDivision {
  id?: number;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  division?: IDivision | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserDivision> = {};
