import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Election from './election';
import ElectionDetail from './election-detail';
import ElectionUpdate from './election-update';
import ElectionDeleteDialog from './election-delete-dialog';

const ElectionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Election />} />
    <Route path="new" element={<ElectionUpdate />} />
    <Route path=":id">
      <Route index element={<ElectionDetail />} />
      <Route path="edit" element={<ElectionUpdate />} />
      <Route path="delete" element={<ElectionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ElectionRoutes;
