import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-device.reducer';

export const UserDeviceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userDeviceEntity = useAppSelector(state => state.userDevice.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userDeviceDetailsHeading">
          <Translate contentKey="voterApp.userDevice.detail.title">UserDevice</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userDeviceEntity.id}</dd>
          <dt>
            <span id="userId">
              <Translate contentKey="voterApp.userDevice.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{userDeviceEntity.userId}</dd>
          <dt>
            <span id="deviceId">
              <Translate contentKey="voterApp.userDevice.deviceId">Device Id</Translate>
            </span>
          </dt>
          <dd>{userDeviceEntity.deviceId}</dd>
          <dt>
            <span id="expoToken">
              <Translate contentKey="voterApp.userDevice.expoToken">Expo Token</Translate>
            </span>
          </dt>
          <dd>{userDeviceEntity.expoToken}</dd>
          <dt>
            <span id="fcmToken">
              <Translate contentKey="voterApp.userDevice.fcmToken">Fcm Token</Translate>
            </span>
          </dt>
          <dd>{userDeviceEntity.fcmToken}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="voterApp.userDevice.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{userDeviceEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="voterApp.userDevice.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {userDeviceEntity.createdAt ? <TextFormat value={userDeviceEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="voterApp.userDevice.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {userDeviceEntity.modifiedAt ? <TextFormat value={userDeviceEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="isTest">
              <Translate contentKey="voterApp.userDevice.isTest">Is Test</Translate>
            </span>
          </dt>
          <dd>{userDeviceEntity.isTest ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/user-device" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-device/${userDeviceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserDeviceDetail;
