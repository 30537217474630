import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PollDivision from './poll-division';
import PollDivisionDetail from './poll-division-detail';
import PollDivisionUpdate from './poll-division-update';
import PollDivisionDeleteDialog from './poll-division-delete-dialog';

const PollDivisionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PollDivision />} />
    <Route path="new" element={<PollDivisionUpdate />} />
    <Route path=":id">
      <Route index element={<PollDivisionDetail />} />
      <Route path="edit" element={<PollDivisionUpdate />} />
      <Route path="delete" element={<PollDivisionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PollDivisionRoutes;
