import dayjs from 'dayjs';
import { IQuestion } from 'app/shared/model/question.model';

export interface IOption {
  id?: number;
  serialNo?: number | null;
  option?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  question?: IQuestion | null;
}

export const defaultValue: Readonly<IOption> = {};
