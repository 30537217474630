import dayjs from 'dayjs';
import { IUserContestInfo } from 'app/shared/model/user-contest-info.model';
import { IPolitician } from 'app/shared/model/politician.model';

export interface ICandidate {
  id?: number;
  orderOnBallot?: number | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  userContestInfo?: IUserContestInfo | null;
  politician?: IPolitician | null;
}

export const defaultValue: Readonly<ICandidate> = {};
