import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Poll from './poll';
import PollDetail from './poll-detail';
import PollUpdate from './poll-update';
import PollDeleteDialog from './poll-delete-dialog';

const PollRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Poll />} />
    <Route path="new" element={<PollUpdate />} />
    <Route path=":id">
      <Route index element={<PollDetail />} />
      <Route path="edit" element={<PollUpdate />} />
      <Route path="delete" element={<PollDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PollRoutes;
