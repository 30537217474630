import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './poll-response.reducer';

export const PollResponseDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const pollResponseEntity = useAppSelector(state => state.pollResponse.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="pollResponseDetailsHeading">
          <Translate contentKey="voterApp.pollResponse.detail.title">PollResponse</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.id}</dd>
          <dt>
            <span id="userId">
              <Translate contentKey="voterApp.pollResponse.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.userId}</dd>
          <dt>
            <span id="pollId">
              <Translate contentKey="voterApp.pollResponse.pollId">Poll Id</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.pollId}</dd>
          <dt>
            <span id="questionId">
              <Translate contentKey="voterApp.pollResponse.questionId">Question Id</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.questionId}</dd>
          <dt>
            <span id="optionId">
              <Translate contentKey="voterApp.pollResponse.optionId">Option Id</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.optionId}</dd>
          <dt>
            <span id="optionalResponse">
              <Translate contentKey="voterApp.pollResponse.optionalResponse">Optional Response</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.optionalResponse}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="voterApp.pollResponse.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="voterApp.pollResponse.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {pollResponseEntity.createdDate ? (
              <TextFormat value={pollResponseEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="voterApp.pollResponse.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{pollResponseEntity.lastModifiedBy}</dd>
          <dt>
            <span id="lastModifiedAt">
              <Translate contentKey="voterApp.pollResponse.lastModifiedAt">Last Modified At</Translate>
            </span>
          </dt>
          <dd>
            {pollResponseEntity.lastModifiedAt ? (
              <TextFormat value={pollResponseEntity.lastModifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/poll-response" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/poll-response/${pollResponseEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PollResponseDetail;
