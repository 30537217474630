import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Issues from './issues';
import IssuesDetail from './issues-detail';
import IssuesUpdate from './issues-update';
import IssuesDeleteDialog from './issues-delete-dialog';

const IssuesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Issues />} />
    <Route path="new" element={<IssuesUpdate />} />
    <Route path=":id">
      <Route index element={<IssuesDetail />} />
      <Route path="edit" element={<IssuesUpdate />} />
      <Route path="delete" element={<IssuesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default IssuesRoutes;
