import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './politician.reducer';

export const PoliticianDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const politicianEntity = useAppSelector(state => state.politician.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="politicianDetailsHeading">
          <Translate contentKey="voterApp.politician.detail.title">Politician</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.id}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="voterApp.politician.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="voterApp.politician.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.lastName}</dd>
          <dt>
            <span id="imagePath">
              <Translate contentKey="voterApp.politician.imagePath">Image Path</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.imagePath}</dd>
          <dt>
            <span id="party">
              <Translate contentKey="voterApp.politician.party">Party</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.party}</dd>
          <dt>
            <span id="dob">
              <Translate contentKey="voterApp.politician.dob">Dob</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.dob ? <TextFormat value={politicianEntity.dob} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="age">
              <Translate contentKey="voterApp.politician.age">Age</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.age}</dd>
          <dt>
            <span id="birthPlace">
              <Translate contentKey="voterApp.politician.birthPlace">Birth Place</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.birthPlace}</dd>
          <dt>
            <span id="about">
              <Translate contentKey="voterApp.politician.about">About</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.about}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="voterApp.politician.email">Email</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.email}</dd>
          <dt>
            <span id="phoneNumber">
              <Translate contentKey="voterApp.politician.phoneNumber">Phone Number</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.phoneNumber}</dd>
          <dt>
            <span id="level">
              <Translate contentKey="voterApp.politician.level">Level</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.level}</dd>
          <dt>
            <span id="office">
              <Translate contentKey="voterApp.politician.office">Office</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.office}</dd>
          <dt>
            <span id="ocdDivisionId">
              <Translate contentKey="voterApp.politician.ocdDivisionId">Ocd Division Id</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.ocdDivisionId}</dd>
          <dt>
            <span id="twitterHandle">
              <Translate contentKey="voterApp.politician.twitterHandle">Twitter Handle</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.twitterHandle}</dd>
          <dt>
            <span id="facebookHandle">
              <Translate contentKey="voterApp.politician.facebookHandle">Facebook Handle</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.facebookHandle}</dd>
          <dt>
            <span id="isLeader">
              <Translate contentKey="voterApp.politician.isLeader">Is Leader</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.isLeader ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="voterApp.politician.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="voterApp.politician.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {politicianEntity.createdDate ? <TextFormat value={politicianEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="voterApp.politician.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{politicianEntity.lastModifiedBy}</dd>
          <dt>
            <span id="lastModifiedAt">
              <Translate contentKey="voterApp.politician.lastModifiedAt">Last Modified At</Translate>
            </span>
          </dt>
          <dd>
            {politicianEntity.lastModifiedAt ? (
              <TextFormat value={politicianEntity.lastModifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/politician" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/politician/${politicianEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PoliticianDetail;
