import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Politician from './politician';
import PoliticianDetail from './politician-detail';
import PoliticianUpdate from './politician-update';
import PoliticianDeleteDialog from './politician-delete-dialog';

const PoliticianRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Politician />} />
    <Route path="new" element={<PoliticianUpdate />} />
    <Route path=":id">
      <Route index element={<PoliticianDetail />} />
      <Route path="edit" element={<PoliticianUpdate />} />
      <Route path="delete" element={<PoliticianDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PoliticianRoutes;
