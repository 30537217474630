import { IPolitician } from 'app/shared/model/politician.model';
import { IIssues } from 'app/shared/model/issues.model';

export interface IPoliticianIssues {
  id?: number;
  support?: string | null;
  politician?: IPolitician | null;
  issues?: IIssues | null;
}

export const defaultValue: Readonly<IPoliticianIssues> = {};
