import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPoll } from 'app/shared/model/poll.model';
import { getEntities as getPolls } from 'app/entities/poll/poll.reducer';
import { IDivision } from 'app/shared/model/division.model';
import { getEntities as getDivisions } from 'app/entities/division/division.reducer';
import { IPollDivision } from 'app/shared/model/poll-division.model';
import { getEntity, updateEntity, createEntity, reset } from './poll-division.reducer';

export const PollDivisionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const polls = useAppSelector(state => state.poll.entities);
  const divisions = useAppSelector(state => state.division.entities);
  const pollDivisionEntity = useAppSelector(state => state.pollDivision.entity);
  const loading = useAppSelector(state => state.pollDivision.loading);
  const updating = useAppSelector(state => state.pollDivision.updating);
  const updateSuccess = useAppSelector(state => state.pollDivision.updateSuccess);

  const handleClose = () => {
    navigate('/poll-division');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPolls({}));
    dispatch(getDivisions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedAt = convertDateTimeToServer(values.lastModifiedAt);

    const entity = {
      ...pollDivisionEntity,
      ...values,
      poll: polls.find(it => it.id.toString() === values.poll.toString()),
      division: divisions.find(it => it.id.toString() === values.division.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedAt: displayDefaultDateTime(),
        }
      : {
          ...pollDivisionEntity,
          createdDate: convertDateTimeFromServer(pollDivisionEntity.createdDate),
          lastModifiedAt: convertDateTimeFromServer(pollDivisionEntity.lastModifiedAt),
          poll: pollDivisionEntity?.poll?.id,
          division: pollDivisionEntity?.division?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="voterApp.pollDivision.home.createOrEditLabel" data-cy="PollDivisionCreateUpdateHeading">
            <Translate contentKey="voterApp.pollDivision.home.createOrEditLabel">Create or edit a PollDivision</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="poll-division-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('voterApp.pollDivision.createdBy')}
                id="poll-division-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.pollDivision.createdDate')}
                id="poll-division-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('voterApp.pollDivision.lastModifiedBy')}
                id="poll-division-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.pollDivision.lastModifiedAt')}
                id="poll-division-lastModifiedAt"
                name="lastModifiedAt"
                data-cy="lastModifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="poll-division-poll"
                name="poll"
                data-cy="poll"
                label={translate('voterApp.pollDivision.poll')}
                type="select"
              >
                <option value="" key="0" />
                {polls
                  ? polls.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="poll-division-division"
                name="division"
                data-cy="division"
                label={translate('voterApp.pollDivision.division')}
                type="select"
              >
                <option value="" key="0" />
                {divisions
                  ? divisions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/poll-division" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PollDivisionUpdate;
