import dayjs from 'dayjs';
import { ICandidate } from 'app/shared/model/candidate.model';
import { IElection } from 'app/shared/model/election.model';

export interface IUserContestInfo {
  id?: number;
  type?: string | null;
  office?: string | null;
  level?: string | null;
  ballotTitle?: string | null;
  userID?: number | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  pollingLocations?: string | null;
  description?: string | null;
  candidates?: ICandidate[] | null;
  election?: IElection | null;
}

export const defaultValue: Readonly<IUserContestInfo> = {};
