import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/user-info">
        <Translate contentKey="global.menu.entities.userInfo"/>
      </MenuItem>
      <MenuItem icon="asterisk" to="/refresh-token">
        <Translate contentKey="global.menu.entities.refreshToken" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/division">
        <Translate contentKey="global.menu.entities.division" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-division">
        <Translate contentKey="global.menu.entities.userDivision" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/poll">
        <Translate contentKey="global.menu.entities.poll" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/question">
        <Translate contentKey="global.menu.entities.question" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/option">
        <Translate contentKey="global.menu.entities.option" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/poll-division">
        <Translate contentKey="global.menu.entities.pollDivision" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/poll-response">
        <Translate contentKey="global.menu.entities.pollResponse" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/issues">
        <Translate contentKey="global.menu.entities.issues" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/election">
        <Translate contentKey="global.menu.entities.election" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/candidate">
        <Translate contentKey="global.menu.entities.candidate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-contest-info">
        <Translate contentKey="global.menu.entities.userContestInfo" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/politician">
        <Translate contentKey="global.menu.entities.politician" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/politician-issues">
        <Translate contentKey="global.menu.entities.politicianIssues" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-device">
        <Translate contentKey="global.menu.entities.userDevice" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
