import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './refresh-token.reducer';

export const RefreshTokenDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const refreshTokenEntity = useAppSelector(state => state.refreshToken.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="refreshTokenDetailsHeading">
          <Translate contentKey="voterApp.refreshToken.detail.title">RefreshToken</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{refreshTokenEntity.id}</dd>
          <dt>
            <span id="token">
              <Translate contentKey="voterApp.refreshToken.token">Token</Translate>
            </span>
          </dt>
          <dd>{refreshTokenEntity.token}</dd>
          <dt>
            <span id="expiryDate">
              <Translate contentKey="voterApp.refreshToken.expiryDate">Expiry Date</Translate>
            </span>
          </dt>
          <dd>
            {refreshTokenEntity.expiryDate ? (
              <TextFormat value={refreshTokenEntity.expiryDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="voterApp.refreshToken.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{refreshTokenEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="voterApp.refreshToken.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {refreshTokenEntity.createdDate ? (
              <TextFormat value={refreshTokenEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="voterApp.refreshToken.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{refreshTokenEntity.lastModifiedBy}</dd>
          <dt>
            <span id="lastModifiedAt">
              <Translate contentKey="voterApp.refreshToken.lastModifiedAt">Last Modified At</Translate>
            </span>
          </dt>
          <dd>
            {refreshTokenEntity.lastModifiedAt ? (
              <TextFormat value={refreshTokenEntity.lastModifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="voterApp.refreshToken.user">User</Translate>
          </dt>
          <dd>{refreshTokenEntity.user ? refreshTokenEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/refresh-token" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/refresh-token/${refreshTokenEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RefreshTokenDetail;
