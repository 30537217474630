import dayjs from 'dayjs';
import { IPollDivision } from 'app/shared/model/poll-division.model';
import { IUserDivision } from 'app/shared/model/user-division.model';

export interface IDivision {
  id?: number;
  ocdDivisionId?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  pollDivisions?: IPollDivision[] | null;
  userDivisions?: IUserDivision[] | null;
}

export const defaultValue: Readonly<IDivision> = {};
