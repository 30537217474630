import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserInfo from './user-info';
import RefreshToken from './refresh-token';
import Division from './division';
import UserDivision from './user-division';
import Poll from './poll';
import Question from './question';
import Option from './option';
import PollDivision from './poll-division';
import PollResponse from './poll-response';
import Issues from './issues';
import Election from './election';
import Candidate from './candidate';
import UserContestInfo from './user-contest-info';
import Politician from './politician';
import PoliticianIssues from './politician-issues';
import UserDevice from './user-device';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="user-info/*" element={<UserInfo/>}/>
        <Route path="refresh-token/*" element={<RefreshToken />} />
        <Route path="division/*" element={<Division />} />
        <Route path="user-division/*" element={<UserDivision />} />
        <Route path="poll/*" element={<Poll />} />
        <Route path="question/*" element={<Question />} />
        <Route path="option/*" element={<Option />} />
        <Route path="poll-division/*" element={<PollDivision />} />
        <Route path="poll-response/*" element={<PollResponse />} />
        <Route path="issues/*" element={<Issues />} />
        <Route path="election/*" element={<Election />} />
        <Route path="candidate/*" element={<Candidate />} />
        <Route path="user-contest-info/*" element={<UserContestInfo />} />
        <Route path="politician/*" element={<Politician />} />
        <Route path="politician-issues/*" element={<PoliticianIssues />} />
        <Route path="user-device/*" element={<UserDevice />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
