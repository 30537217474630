import userInfo from 'app/entities/user-info/user-info.reducer';
import refreshToken from 'app/entities/refresh-token/refresh-token.reducer';
import division from 'app/entities/division/division.reducer';
import userDivision from 'app/entities/user-division/user-division.reducer';
import poll from 'app/entities/poll/poll.reducer';
import question from 'app/entities/question/question.reducer';
import option from 'app/entities/option/option.reducer';
import pollDivision from 'app/entities/poll-division/poll-division.reducer';
import pollResponse from 'app/entities/poll-response/poll-response.reducer';
import issues from 'app/entities/issues/issues.reducer';
import election from 'app/entities/election/election.reducer';
import candidate from 'app/entities/candidate/candidate.reducer';
import userContestInfo from 'app/entities/user-contest-info/user-contest-info.reducer';
import politician from 'app/entities/politician/politician.reducer';
import politicianIssues from 'app/entities/politician-issues/politician-issues.reducer';
import userDevice from 'app/entities/user-device/user-device.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  userInfo,
  refreshToken,
  division,
  userDivision,
  poll,
  question,
  option,
  pollDivision,
  pollResponse,
  issues,
  election,
  candidate,
  userContestInfo,
  politician,
  politicianIssues,
  userDevice,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
