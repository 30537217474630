import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPolitician } from 'app/shared/model/politician.model';
import { getEntity, updateEntity, createEntity, reset } from './politician.reducer';

export const PoliticianUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const politicianEntity = useAppSelector(state => state.politician.entity);
  const loading = useAppSelector(state => state.politician.loading);
  const updating = useAppSelector(state => state.politician.updating);
  const updateSuccess = useAppSelector(state => state.politician.updateSuccess);

  const handleClose = () => {
    navigate('/politician');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.lastModifiedAt = convertDateTimeToServer(values.lastModifiedAt);

    const entity = {
      ...politicianEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          lastModifiedAt: displayDefaultDateTime(),
        }
      : {
          ...politicianEntity,
          createdDate: convertDateTimeFromServer(politicianEntity.createdDate),
          lastModifiedAt: convertDateTimeFromServer(politicianEntity.lastModifiedAt),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="voterApp.politician.home.createOrEditLabel" data-cy="PoliticianCreateUpdateHeading">
            <Translate contentKey="voterApp.politician.home.createOrEditLabel">Create or edit a Politician</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="politician-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('voterApp.politician.firstName')}
                id="politician-firstName"
                name="firstName"
                data-cy="firstName"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.lastName')}
                id="politician-lastName"
                name="lastName"
                data-cy="lastName"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.imagePath')}
                id="politician-imagePath"
                name="imagePath"
                data-cy="imagePath"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.party')}
                id="politician-party"
                name="party"
                data-cy="party"
                type="text"
              />
              <ValidatedField label={translate('voterApp.politician.dob')} id="politician-dob" name="dob" data-cy="dob" type="date" />
              <ValidatedField label={translate('voterApp.politician.age')} id="politician-age" name="age" data-cy="age" type="text" />
              <ValidatedField
                label={translate('voterApp.politician.birthPlace')}
                id="politician-birthPlace"
                name="birthPlace"
                data-cy="birthPlace"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.about')}
                id="politician-about"
                name="about"
                data-cy="about"
                type="textarea"
              />
              <ValidatedField
                label={translate('voterApp.politician.email')}
                id="politician-email"
                name="email"
                data-cy="email"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.phoneNumber')}
                id="politician-phoneNumber"
                name="phoneNumber"
                data-cy="phoneNumber"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.level')}
                id="politician-level"
                name="level"
                data-cy="level"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.office')}
                id="politician-office"
                name="office"
                data-cy="office"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.ocdDivisionId')}
                id="politician-ocdDivisionId"
                name="ocdDivisionId"
                data-cy="ocdDivisionId"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.twitterHandle')}
                id="politician-twitterHandle"
                name="twitterHandle"
                data-cy="twitterHandle"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.facebookHandle')}
                id="politician-facebookHandle"
                name="facebookHandle"
                data-cy="facebookHandle"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.isLeader')}
                id="politician-isLeader"
                name="isLeader"
                data-cy="isLeader"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('voterApp.politician.createdBy')}
                id="politician-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.createdDate')}
                id="politician-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('voterApp.politician.lastModifiedBy')}
                id="politician-lastModifiedBy"
                name="lastModifiedBy"
                data-cy="lastModifiedBy"
                type="text"
              />
              <ValidatedField
                label={translate('voterApp.politician.lastModifiedAt')}
                id="politician-lastModifiedAt"
                name="lastModifiedAt"
                data-cy="lastModifiedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/politician" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PoliticianUpdate;
