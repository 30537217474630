import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './poll.reducer';

export const PollDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const pollEntity = useAppSelector(state => state.poll.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="pollDetailsHeading">
          <Translate contentKey="voterApp.poll.detail.title">Poll</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{pollEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="voterApp.poll.title">Title</Translate>
            </span>
          </dt>
          <dd>{pollEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="voterApp.poll.description">Description</Translate>
            </span>
          </dt>
          <dd>{pollEntity.description}</dd>
          <dt>
            <span id="tip">
              <Translate contentKey="voterApp.poll.tip">Tip</Translate>
            </span>
          </dt>
          <dd>{pollEntity.tip}</dd>
          <dt>
            <span id="successMessage">
              <Translate contentKey="voterApp.poll.successMessage">Success Message</Translate>
            </span>
          </dt>
          <dd>{pollEntity.successMessage}</dd>
          <dt>
            <span id="incompleteMessage">
              <Translate contentKey="voterApp.poll.incompleteMessage">Incomplete Message</Translate>
            </span>
          </dt>
          <dd>{pollEntity.incompleteMessage}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="voterApp.poll.status">Status</Translate>
            </span>
          </dt>
          <dd>{pollEntity.status}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="voterApp.poll.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{pollEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="voterApp.poll.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{pollEntity.createdDate ? <TextFormat value={pollEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="voterApp.poll.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{pollEntity.lastModifiedBy}</dd>
          <dt>
            <span id="lastModifiedAt">
              <Translate contentKey="voterApp.poll.lastModifiedAt">Last Modified At</Translate>
            </span>
          </dt>
          <dd>
            {pollEntity.lastModifiedAt ? <TextFormat value={pollEntity.lastModifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="totalQuestions">
              <Translate contentKey="voterApp.poll.totalQuestions">Total Questions</Translate>
            </span>
          </dt>
          <dd>{pollEntity.totalQuestions}</dd>
        </dl>
        <Button tag={Link} to="/poll" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/poll/${pollEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PollDetail;
