import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-info.reducer';

export const UserInfoDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userInfoEntity = useAppSelector(state => state.userInfo.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userInfoDetailsHeading">
          <Translate contentKey="voterApp.userInfo.detail.title">UserInfo</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.id}</dd>
          <dt>
            <span id="age">
              <Translate contentKey="voterApp.userInfo.age">Age</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.age}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="voterApp.userInfo.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.gender}</dd>
          <dt>
            <span id="address">
              <Translate contentKey="voterApp.userInfo.address">Address</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.address}</dd>
          <dt>
            <span id="latitude">
              <Translate contentKey="voterApp.userInfo.latitude">Latitude</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.latitude}</dd>
          <dt>
            <span id="longitude">
              <Translate contentKey="voterApp.userInfo.longitude">Longitude</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.longitude}</dd>
          <dt>
            <span id="city">
              <Translate contentKey="voterApp.userInfo.city">City</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.city}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="voterApp.userInfo.state">State</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.state}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="voterApp.userInfo.country">Country</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.country}</dd>
          <dt>
            <span id="zipcode">
              <Translate contentKey="voterApp.userInfo.zipcode">Zipcode</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.zipcode}</dd>
          <dt>
            <span id="placeId">
              <Translate contentKey="voterApp.userInfo.placeId">Place Id</Translate>
            </span>
          </dt>
          <dd>{userInfoEntity.placeId}</dd>
          <dt>
            <Translate contentKey="voterApp.userInfo.user">User</Translate>
          </dt>
          <dd>{userInfoEntity.user ? userInfoEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-info" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-info/${userInfoEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserInfoDetail;
