import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IRefreshToken {
  id?: number;
  token?: string | null;
  expiryDate?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IRefreshToken> = {};
