import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserDivision from './user-division';
import UserDivisionDetail from './user-division-detail';
import UserDivisionUpdate from './user-division-update';
import UserDivisionDeleteDialog from './user-division-delete-dialog';

const UserDivisionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserDivision />} />
    <Route path="new" element={<UserDivisionUpdate />} />
    <Route path=":id">
      <Route index element={<UserDivisionDetail />} />
      <Route path="edit" element={<UserDivisionUpdate />} />
      <Route path="delete" element={<UserDivisionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserDivisionRoutes;
