import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './question.reducer';

export const QuestionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const questionEntity = useAppSelector(state => state.question.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="questionDetailsHeading">
          <Translate contentKey="voterApp.question.detail.title">Question</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{questionEntity.id}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="voterApp.question.description">Description</Translate>
            </span>
          </dt>
          <dd>{questionEntity.description}</dd>
          <dt>
            <span id="serialNo">
              <Translate contentKey="voterApp.question.serialNo">Serial No</Translate>
            </span>
          </dt>
          <dd>{questionEntity.serialNo}</dd>
          <dt>
            <span id="pollType">
              <Translate contentKey="voterApp.question.pollType">Poll Type</Translate>
            </span>
          </dt>
          <dd>{questionEntity.pollType}</dd>
          <dt>
            <span id="question">
              <Translate contentKey="voterApp.question.question">Question</Translate>
            </span>
          </dt>
          <dd>{questionEntity.question}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="voterApp.question.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{questionEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="voterApp.question.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {questionEntity.createdDate ? <TextFormat value={questionEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="voterApp.question.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{questionEntity.lastModifiedBy}</dd>
          <dt>
            <span id="lastModifiedAt">
              <Translate contentKey="voterApp.question.lastModifiedAt">Last Modified At</Translate>
            </span>
          </dt>
          <dd>
            {questionEntity.lastModifiedAt ? (
              <TextFormat value={questionEntity.lastModifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="voterApp.question.poll">Poll</Translate>
          </dt>
          <dd>{questionEntity.poll ? questionEntity.poll.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/question" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/question/${questionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default QuestionDetail;
