import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PoliticianIssues from './politician-issues';
import PoliticianIssuesDetail from './politician-issues-detail';
import PoliticianIssuesUpdate from './politician-issues-update';
import PoliticianIssuesDeleteDialog from './politician-issues-delete-dialog';

const PoliticianIssuesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PoliticianIssues />} />
    <Route path="new" element={<PoliticianIssuesUpdate />} />
    <Route path=":id">
      <Route index element={<PoliticianIssuesDetail />} />
      <Route path="edit" element={<PoliticianIssuesUpdate />} />
      <Route path="delete" element={<PoliticianIssuesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PoliticianIssuesRoutes;
