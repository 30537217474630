import dayjs from 'dayjs';
import { IPoll } from 'app/shared/model/poll.model';
import { IDivision } from 'app/shared/model/division.model';

export interface IPollDivision {
  id?: number;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  poll?: IPoll | null;
  division?: IDivision | null;
}

export const defaultValue: Readonly<IPollDivision> = {};
