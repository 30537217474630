import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PollResponse from './poll-response';
import PollResponseDetail from './poll-response-detail';
import PollResponseUpdate from './poll-response-update';
import PollResponseDeleteDialog from './poll-response-delete-dialog';

const PollResponseRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PollResponse />} />
    <Route path="new" element={<PollResponseUpdate />} />
    <Route path=":id">
      <Route index element={<PollResponseDetail />} />
      <Route path="edit" element={<PollResponseUpdate />} />
      <Route path="delete" element={<PollResponseDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PollResponseRoutes;
