import dayjs from 'dayjs';

export interface IPollResponse {
  id?: number;
  userId?: number | null;
  pollId?: number | null;
  questionId?: number | null;
  optionId?: number | null;
  optionalResponse?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
}

export const defaultValue: Readonly<IPollResponse> = {};
