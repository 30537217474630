import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-contest-info.reducer';

export const UserContestInfoDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userContestInfoEntity = useAppSelector(state => state.userContestInfo.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userContestInfoDetailsHeading">
          <Translate contentKey="voterApp.userContestInfo.detail.title">UserContestInfo</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.id}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="voterApp.userContestInfo.type">Type</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.type}</dd>
          <dt>
            <span id="office">
              <Translate contentKey="voterApp.userContestInfo.office">Office</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.office}</dd>
          <dt>
            <span id="level">
              <Translate contentKey="voterApp.userContestInfo.level">Level</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.level}</dd>
          <dt>
            <span id="ballotTitle">
              <Translate contentKey="voterApp.userContestInfo.ballotTitle">Ballot Title</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.ballotTitle}</dd>
          <dt>
            <span id="userID">
              <Translate contentKey="voterApp.userContestInfo.userID">User ID</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.userID}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="voterApp.userContestInfo.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="voterApp.userContestInfo.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {userContestInfoEntity.createdDate ? (
              <TextFormat value={userContestInfoEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="voterApp.userContestInfo.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.lastModifiedBy}</dd>
          <dt>
            <span id="lastModifiedAt">
              <Translate contentKey="voterApp.userContestInfo.lastModifiedAt">Last Modified At</Translate>
            </span>
          </dt>
          <dd>
            {userContestInfoEntity.lastModifiedAt ? (
              <TextFormat value={userContestInfoEntity.lastModifiedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="pollingLocations">
              <Translate contentKey="voterApp.userContestInfo.pollingLocations">Polling Locations</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.pollingLocations}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="voterApp.userContestInfo.description">Description</Translate>
            </span>
          </dt>
          <dd>{userContestInfoEntity.description}</dd>
          <dt>
            <Translate contentKey="voterApp.userContestInfo.election">Election</Translate>
          </dt>
          <dd>{userContestInfoEntity.election ? userContestInfoEntity.election.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-contest-info" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-contest-info/${userContestInfoEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserContestInfoDetail;
