import dayjs from 'dayjs';
import { IUserContestInfo } from 'app/shared/model/user-contest-info.model';

export interface IElection {
  id?: number;
  electionID?: string | null;
  name?: string | null;
  electionDate?: string | null;
  ocdDivisionId?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedAt?: string | null;
  userContestInfos?: IUserContestInfo[] | null;
}

export const defaultValue: Readonly<IElection> = {};
