import dayjs from 'dayjs';

export interface IUserDevice {
  id?: number;
  userId?: number | null;
  deviceId?: string | null;
  expoToken?: string | null;
  fcmToken?: string | null;
  isActive?: boolean | null;
  createdAt?: string | null;
  modifiedAt?: string | null;
  isTest?: boolean | null;
}

export const defaultValue: Readonly<IUserDevice> = {
  isActive: false,
  isTest: false,
};
